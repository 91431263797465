import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { Tags } from '@tryghost/helpers-gatsby'
import { readingTime as readingTimeHelper } from '@tryghost/helpers'

const PostCard = ({ post }) => {
    const url = `${post.slug}/`
    const readingTime = readingTimeHelper(post)

    return (
        <div className="post-card">
            <header className="post-card-header">
                {post.feature_image &&
                    <Link to={url} className="silent-link">
                        <div className="post-card-image" style={{ backgroundImage: `url(${post.feature_image})` }} />
                    </Link>
                }
                {post.tags && <div className="post-card-tags"> <Tags linkClasses="silent-link" post={post} permalink="/tag/:slug/" visibility="public" /></div>}
                {post.featured && <span>Featured</span>}
                <Link to={url} className="silent-link">
                    <h2 className="post-card-title">{post.title}</h2>
                </Link>
            </header>
            <Link to={url} className="silent-link">
                <section className="post-card-excerpt">{post.excerpt}</section>
            </Link>
            <footer className="post-card-footer">
                <Link to={`author/${post.primary_author.slug}`} className="silent-link">
                    <div className="post-card-footer-left">
                        <div className="post-card-avatar">
                            {post.primary_author.profile_image ?
                                <img className="author-profile-image" src={post.primary_author.profile_image} alt={post.primary_author.name}/> :
                                <img className="default-avatar" src={`/images/icons/avatar.svg`} alt={post.primary_author.name}/>
                            }
                        </div>
                        <span>{ post.primary_author.name }</span>
                    </div>
                </Link>
                <div className="post-card-footer-right">
                    <div>{readingTime}</div>
                </div>
            </footer>
        </div>
    )
}

PostCard.propTypes = {
    post: PropTypes.shape({
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        featured: PropTypes.bool,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
        excerpt: PropTypes.string.isRequired,
        primary_author: PropTypes.shape({
            name: PropTypes.string.isRequired,
            profile_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
}

export default PostCard
