module.exports = {
    siteUrl: `https://www.omnicalculator.com`, // Site domain. Do not include a trailing slash!

    postsPerPage: 12, // Number of posts shown on paginated pages (changes this requires sometimes to delete the cache)

    siteTitleMeta: `Omni Calculator Blog`, // This allows an alternative site title for meta data for pages.
    siteDescriptionMeta: `Getting the numbers you need.`, // This allows an alternative site description for meta data for pages.

    publisher: {
        legalName: `Omni Calculator sp. z o. o.`,
        logo: {
            height: `500px`,
            url: ` https://cdn.omnicalculator.com/favicons/schema-logo-cd26179e0f.png`,
            width: `1213px`,
        },
        name: `Omni Calculator`,
    },

    shareImage: {
        height: `630px`,
        url: `https://og-imgs.omnicalculator.com/homepage/762.png`, // fallback image for meta data. Will be used, when no post/tag/author image specified. 1200x1200 is recommended
        width: `1200px`,
    },

    shortTitle: `OmniBlog`, // Used for App manifest e.g. Mobile Home Screen
    siteIcon: `favicon.png`, // Logo in /static dir used for SEO, RSS, and App manifest
    backgroundColor: `#e9e9e9`, // Used for Offline Manifest
    themeColor: `#15171A`, // Used for Offline Manifest
}
